class TokenStore{
  set auth(value){
    sessionStorage.auth = value || ''
    localStorage.auth = value || ''
  }

  get auth(){
    return sessionStorage.auth ?? localStorage.auth
  }

  set refresh(value){
    sessionStorage.refresh = value || ''
    localStorage.refresh = value || ''
  }

  get refresh(){
    return sessionStorage.refresh ?? localStorage.refresh
  }

  protected = endpoint => {
    endpoint.headers(headers => {
      if(this.auth) {
        headers.Authorization = this.auth
        headers['X-Authorization'] = this.auth
      }
      return headers
    })

    endpoint.resultsHandler(result => {
      if(result && result.data && result.data.type === 'credentials' && result.data.attributes){
        this.auth    = result.data.attributes.auth
        this.refresh = result.data.attributes.refresh
      }
      return result
    })
    return endpoint
  }
}

const TokenStoreSingleton = new TokenStore()
const ProtectResourceDecorator = TokenStoreSingleton.protected.bind(TokenStoreSingleton)
export { TokenStoreSingleton as TokenStore, ProtectResourceDecorator }
