import ActionSet from './ActionSet'
import AuditActions from './AuditActionSet'
import BenchmarkPointActions from './BenchmarkPointActionSet'
import CustodianActions from './CustodianActionSet'
import DerivedMetricTypeActions from './DerivedMetricTypeActionSet'
import ExchangeRateActions from './ExchangeRateActionSet'
import FileImportActions from './FileImportActionSet'
import FirmAssetActions from './FirmAssetActionSet'
import FundActions from './FundActionSet'
import FundBenchmarkActions from './FundBenchmarkActionSet'
import FundCategoryActions from './FundCategoryActionSet'
import FundReturnActions from './FundReturnActionSet'
import FundSummaryActions from './FundSummaryActionSet'
import FundUserFavouriteActions from './FundUserFavouriteActionSet'
import IndexActions from './IndexActionSet'
import IndexPointActions from './IndexPointActionSet'
import IssuerActions from './IssuerActionSet'
import MetricTypeActions from './MetricTypeActionSet'
import NotificationActions from './NotificationActionSet'
import ReferenceRateActions from './ReferenceRateActionSet'
import ResetActions from './ResetActionSet'
import SchemeActions from './SchemeActionSet'
import SnackbarActions from './SnackbarActionSet'
import TokenActions from './TokenActionSet'
import UserActions from './UserActionSet'
import UserFavouriteFilterActions from './UserFavouriteFilterActionSet'
import VersionActions from './VersionActionSet'

export const reducers = {
  audits:      AuditActions.reducers,
  benchmarkPoints: BenchmarkPointActions.reducers,
  custodians:  CustodianActions.reducers,
  derivedMetricTypes: DerivedMetricTypeActions.reducers,
  exchangeRates: ExchangeRateActions.reducers,
  fileImports: FileImportActions.reducers,
  firmAssets: FirmAssetActions.reducers,
  fundBenchmarks: FundBenchmarkActions.reducers,
  fundCategories: FundCategoryActions.reducers,
  fundReturns: FundReturnActions.reducers,
  fundSummaries: FundSummaryActions.reducers,
  fundUserFavourites: FundUserFavouriteActions.reducers,
  funds:       FundActions.reducers,
  indexPoints: IndexPointActions.reducers,
  indices:     IndexActions.reducers,
  issuers:     IssuerActions.reducers,
  metricTypes: MetricTypeActions.reducers,
  notifications: NotificationActions.reducers,
  referenceRates: ReferenceRateActions.reducers,
  schemes:     SchemeActions.reducers,
  snackbar:    SnackbarActions.reducers,
  tokens:      TokenActions.reducers,
  userFavouriteFilters: UserFavouriteFilterActions.reducers,
  users:       UserActions.reducers,
  version:     VersionActions.reducers,
}

export {
  ActionSet,
  AuditActions,
  BenchmarkPointActions,
  CustodianActions,
  DerivedMetricTypeActions,
  ExchangeRateActions,
  FileImportActions,
  FirmAssetActions,
  FundActions,
  FundBenchmarkActions,
  FundCategoryActions,
  FundReturnActions,
  FundSummaryActions,
  FundUserFavouriteActions,
  IndexActions,
  IndexPointActions,
  IssuerActions,
  MetricTypeActions,
  NotificationActions,
  ReferenceRateActions,
  ResetActions,
  SchemeActions,
  SnackbarActions,
  TokenActions,
  UserActions,
  UserFavouriteFilterActions,
  VersionActions,
}
