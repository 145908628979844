import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FundCategoryActions, FundActions, FirmAssetActions } from 'actionsets'
import { connectQueryString } from 'containers/shared'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Authorization, compose, formatValue } from 'utils'
import withStyles from 'styles'
import { Pagination } from 'components'
import { MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import DatePicker from 'components/DatePicker'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { submitForm } from 'services/APIResource'
import moment from 'moment'

export class List extends Component {

  constructor(props) {
    super(props)
    FirmAssetActions.bindActions(this)
    FundCategoryActions.bindActions(this, 'fundCategory')
    FundActions.bindActions(this, 'fund')
  }

  state = {}

  loadFirmAssets = () => {
    return this.actions.index({
      pageSize: 16,
      page: this.props.page,
      order: this.sortDir === 'asc' ? 'date' : '-date',
      filter: { ...(this.fromDate && { fromDate: this.fromDate }), ...(this.toDate && { toDate: this.toDate }) },
    })
  }

  handleExportFile = () => {
    const data = {
      Authorization: global.localStorage.auth,
      order: this.sortDir === 'asc' ? 'date' : '-date',
      filter: { ...(this.fromDate && { fromDate: this.fromDate }), ...(this.toDate && { toDate: this.toDate }) },
    }
    submitForm(`/api/firm_assets.xlsx`, data, 'post')
  }

  dependsOn() {
    if (!["HAML", "ALL"].includes(Authorization.selectedIssuerName)) {
      return
    }

    return Promise.all([
      this.loadFirmAssets(),
      this.actions.fundCategory.index({
        fields: { fundCategories: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.actions.fund.index({
        fields: { fundCategories: 'id,shortName' },
        page: 1, pageSize: 2000,
      }),
    ])
  }

  dependenciesMet() {
    return !!this.props.firmAssets
  }

  handleFilterChange = (changes, callback) => {
    this.props.onFilterChange({ ...this.props.filter, ...changes }, callback)
  }

  handleDateChange = (field) => ({ target: { value } }) => {
    this.handleFilterChange({ [field]: ((value && value.length > 9) ? value.substring(0, 10) : value) }, this.loadFirmAssets)
  }

  get fromDate() {
    return this.props.filter.fromDate || ""
  }

  get toDate() {
    return this.props.filter.toDate || ""
  }

  get sortDir() {
    return this.props.filter.sortDir || 'desc'
  }

  handleToggleSortOrder = () => {
    this.handleFilterChange({ sortDir: this.sortDir === 'asc' ? 'desc' : 'asc' }, this.loadFirmAssets)
  }

  get firmAssets() {
    return this.props.firmAssets
  }

  get years() {
    return Array.from({ length: (new Date().getFullYear() - 1999) }, (_, i) => 2000 + i)
  }

  get months() {
    return moment.months().reduce((agg, val, i) => ({ ...agg, [i + 1]: val }), {})
  }

  handleSelectedCategoriesChange = ({ target: { value } }) => {
    this.handleFilterChange({ fundCategoryIds: value.join('-') }, this.loadFirmAssets)
  }

  handlePageSelected = async page => {
    await this.props.onPageChange(page)
    this.loadFirmAssets()
  }

  mapFundCategoryMenuItems = ({ id, name }) =>
    <MenuItem key={id} value={id}>
      <Checkbox checked={!!((this.props.filter.fundCategoryIds || '').split('-')).find(f => f === id)} />
      <ListItemText primary={name} />
    </MenuItem>

  get columns() {
    return this.props.firmAssetsMeta.columns
  }

  render = () => {
    if (!["HAML", "ALL"].includes(Authorization.selectedIssuerName)) {
      return (
        <PageContainer>
          <ActionHeader title="Firm Assets ($)" style={{ marginRight: 16 }} />
          Firm assets are currently implemented only for HAML.
        </PageContainer>
      )
    }

    return <div className={this.props.classes.wrapper}>
      <PageContainer>
        <ActionHeader title="Firm Assets ($)" style={{ marginRight: 16 }}>
          <Button color="secondary" style={{ color: 'white' }} variant="contained" onClick={this.handleExportFile}>Export&emsp;<DownloadIcon /></Button>
        </ActionHeader>
        <DatePicker clearable label="From Date" onChange={this.handleDateChange('fromDate')} value={this.fromDate} style={{ marginRight: 10 }} />
        <DatePicker clearable label="To Date" onChange={this.handleDateChange('toDate')} value={this.toDate} />
        <Pagination totalPages={this.props.firmAssetsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <div className={this.props.classes.containerDiv}>
          <Table>
            <TableBody>
              <TableRow className="header top-labels">
                <TableCell className={this.props.classes.headCol} style={{ backgroundColor: "#005A7A", color: "#FFFFFF" }}>
                  <b>
                    Date
                    <IconButton size='small' style={{ padding: 5, top: 7, marginTop: -15 }} onClick={this.handleToggleSortOrder}>
                      {this.sortDir === 'asc' ? <ArrowUp size='small' /> : <ArrowDown size='small' />}
                    </IconButton>
                  </b>
                </TableCell>
                {this.columns.map(col => <TableCell key={col.name} style={{ backgroundColor: col.bgcolor, color: col.fgcolor }}><b>{col.label}</b></TableCell>)}
              </TableRow>
              <TableRow className="sub-header top-labels">
                <TableCell className={this.props.classes.headCol}></TableCell>
                {this.columns.map(col => <TableCell key={col.name}><b>{col.sublabel}</b></TableCell>)}
              </TableRow>
              {this.firmAssets.map((firmAsset, idx) => {
                return <Fragment key={firmAsset.id}>
                  <TableRow>
                    <TableCell className={this.props.classes.headCol}>
                      {firmAsset.date}
                    </TableCell>
                    {this.columns.map(col => <TableCell key={col.name}>
                      {formatValue(col.format, firmAsset.columns[col.name], 0)}
                    </TableCell>)}
                  </TableRow>
                </Fragment>
              })}
              {this.firmAssets.length === 0 && <TableRow>
                <TableCell colSpan={this.columns.length}>{"No data found for the selected dates"}</TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </div>
        <Pagination totalPages={this.props.firmAssetsTotalPages || 1} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
      </PageContainer>
    </div>
  }
}

const styles = theme => ({
  headCol: {
    position: "absolute",
    width: 150,
    paddingTop: 12,
    left: "0",
    top: "auto",
    padding: 0,
    margin: 0,
    height: 40,
    background: '#D3DCE0',
    '& > b': {
      paddingTop: 0
    }
  },
  containerDiv: {
    overflowX: 'scroll',
    marginLeft: 150,
    marginTop: 4,
    '& .top-labels': {
      height: 30,
      '& $headCol': {
        height: 30,
        marginTop: 1,
      }
    },
    '& .header': {
      background: theme.palette.secondary.light
    },
    '& .sub-header': {
      background: '#D3DCE0'
    },
    '& tr': {
      height: '40px',
      padding: 0,
      margin: 0,
      '&:hover': {
        '& $headCol': {
          background: theme.palette.primary.light,
          fontWeight: 'bold'
        },
        background: theme.palette.primary.light,
        '& td:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          cursor: 'pointer'
        }
      }
    },
    '& tr.header td': {
      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
    },
    '& td': {
      whiteSpace: 'nowrap',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& td.negative': {
      color: 'red',
    }
  },
  wrapper: {
    maxWidth: '100vw',
    overflowX: 'hidden',
    '& > div': {
      width: '85%',
      maxWidth: 4000,
    }
  },
})

export default compose(
  Dependent({ loader: true, clearOnLoad: true }),
  withStyles(styles),
  connectQueryString('fundSummaries'),
  connect(({ firmAssets }) => firmAssets),
  connect(({ funds }) => funds),
  connect(({ fundCategories }) => fundCategories),
)(List)
