import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { TokenActions, VersionActions } from 'actionsets'
import { connect } from 'react-redux'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People'
import FileImportIcon from '@material-ui/icons/InsertDriveFile'
import IndexIcon from '@material-ui/icons/ScatterPlot'
import BenchmarkIcon from '@material-ui/icons/MultilineChart'
import CustodianIcon from '@material-ui/icons/AccountBalance'
import MetricTypeIcon from '@material-ui/icons/ScatterPlot'
import FundIcon from '@material-ui/icons/ShowChart'
import FundCategoryIcon from '@material-ui/icons/Label'
import SchemeIcon from '@material-ui/icons/Category'
import AuditIcon from '@material-ui/icons/Security'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DocumentIcon from '@material-ui/icons/Description'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization } from 'utils'
import { ConditionalRender } from 'components'
import withStyles from 'styles'
import { LogoInner } from 'svg'
import { initials } from 'utils/StringUtils'
import Avatar from '@material-ui/core/Avatar';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { history } from 'utils'
import Tooltip from '@material-ui/core/Tooltip'
import NotificationIcon from '@material-ui/icons/Inbox'
import FundSummaryIcon from '@material-ui/icons/FormatListBulleted'
import FirmAssetsIcon from '@material-ui/icons/AttachMoney'
import Badge from '@material-ui/core/Badge'
import NotificationPopup from 'containers/notifications/NotificationsPopup'
import Select from '@material-ui/core/Select'


export class Nav extends Component {

  constructor(props) {
    super(props)
    TokenActions.bindActions(this, 'tokens')
    VersionActions.bindActions(this, 'versions')
    this.state = { drawerOpen: false }
  }

  componentDidMount() {
    this.actions.versions.getTag()
  }

  isOpen = itemName => !!this.state[`${itemName}Open`]

  handleRequestOpen = itemName => event => {
    event.stopPropagation()
    this.setState({ [`${itemName}Open`]: !this.isOpen(itemName), anchorEl: event.currentTarget });
  }

  handleRequestClose = itemName => () => {
    this.setState({ [`${itemName}Open`]: false});
  }

  open = itemName => !!this.state[`${itemName}Open`]

  visitAndClose = (handler) => e => {
    this.handleRequestClose('drawer')()
    this.handleRequestClose('userMenu')()
    handler(e)
    e.preventDefault()
  }

  handleIssuerChange = async ({target: {value}}) => {
    if (value !== Authorization.selectedIssuerName) {
      await this.actions.tokens.refresh(true, value)
      window.location.reload()
    }
  }

  get path() {
    return window.location.pathname
  }

  render = () =>
    <Fragment>
      <NotificationPopup show={this.open('notifications')} onClose={this.handleRequestOpen('notifications')} />
      <AppBar position="sticky">
        <Toolbar>
          <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({drawerOpen: true})}>
            <MenuIcon/>
          </IconButton>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <Link to='/'><LogoInner style={{marginBottom: -15, marginTop: -10}} width={50} height={50}/> Harbour BSP</Link>
          </Typography>
          <Tooltip title="Notifications">
            <div className={this.props.classes('headerItem', 'iconItem')} onClick={this.handleRequestOpen('notifications')}>
              <Badge className={this.props.classes.notificationBadge} badgeContent={this.props.unread || 0} invisible={!this.props.unread} color="error">
                <NotificationIcon />
              </Badge>
            </div>
          </Tooltip>
          <Select
            className={this.props.classes.issuerSelect}
            classes={{ icon: this.props.classes.issuerSelectIcon }}
            value={Authorization.selectedIssuerName}
            onChange={this.handleIssuerChange}
            MenuProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, getContentAnchorEl: null }}
          >
            <MenuItem value="HAML">HAML</MenuItem>
            <MenuItem value="BNZISL">BNZISL</MenuItem>
            <MenuItem value="ALL">ALL</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        open={this.isOpen('userMenu')}
        onClose={this.handleRequestClose('userMenu')}
      >
        <a onClick={this.visitAndClose(() => history.push('/profile'))} href='/profile'><MenuItem>My Profile</MenuItem></a>
        <MenuItem onClick={this.visitAndClose(this.actions.tokens.destroy)}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="left"
        classes={{root: this.props.classes.drawerRoot}}
        open={this.isOpen('drawer')}
        onClose={() => this.setState({drawerOpen: false})}
        onClick={() => this.setState({drawerOpen: false})}
      >
          <div className={this.props.classes.menuHead}>
            <div className={this.props.classes.avatarRow}>
              <Avatar className={this.props.classes.purpleAvatar}>{initials(this.props.currentUser.name)}</Avatar>
              <LogoInner width={70} height={70}/>
            </div>
            <div className={this.props.classes.userRow}>
              <Button  onClick={this.handleRequestOpen('userMenu')}>
                {this.props.currentUser.name}
                <ArrowDropDownIcon/>
              </Button>
            </div>
          </div>

          <Link to='/file_imports'>
            <ListItem button  selected={RegExp('^/file_imports(/.*)?$').test(this.path)}>
              <ListItemIcon>
                <FileImportIcon/>
              </ListItemIcon>
              <ListItemText primary="File Imports" />
            </ListItem>
          </Link>

          <Link to='/indices'>
            <ListItem button  selected={RegExp('^/indices(/.*)?$').test(this.path)}>
              <ListItemIcon>
                <IndexIcon/>
              </ListItemIcon>
              <ListItemText primary="Indices" />
            </ListItem>
          </Link>

          <Link to='/fund_benchmarks'>
            <ListItem button  selected={RegExp('^/fund_benchmarks(/.*)?$').test(this.path)}>
              <ListItemIcon>
                <BenchmarkIcon/>
              </ListItemIcon>
              <ListItemText primary="Benchmarks" />
            </ListItem>
          </Link>

          <Link to='/funds'>
            <ListItem button  selected={RegExp('^/funds(/.*)?$').test(this.path)}>
              <ListItemIcon>
                <FundIcon/>
              </ListItemIcon>
              <ListItemText primary="Funds" />
            </ListItem>
          </Link>

          <Link to='/fund_summaries'>
            <ListItem button>
              <ListItemIcon>
                <FundSummaryIcon/>
              </ListItemIcon>
              <ListItemText primary="Fund Summary" />
            </ListItem>
          </Link>

          <Link to='/firm_assets'>
            <ListItem button>
              <ListItemIcon>
                <FirmAssetsIcon/>
              </ListItemIcon>
              <ListItemText primary="Firm Assets" />
            </ListItem>
          </Link>

        <Link to='/fund_categories'>
          <ListItem button  selected={RegExp('^/fund_categories(/.*)?$').test(this.path)}>
            <ListItemIcon>
              <FundCategoryIcon/>
            </ListItemIcon>
            <ListItemText primary="Fund Categories" />
          </ListItem>
        </Link>

        <Link to='/schemes'>
          <ListItem button selected={RegExp('^/schemes(/.*)?$').test(this.path)}>
            <ListItemIcon>
              <SchemeIcon/>
            </ListItemIcon>
            <ListItemText primary="Schemes" />
          </ListItem>
        </Link>

        <Link to='/metric_types'>
          <ListItem button  selected={RegExp('^/metric_types(/.*)?$').test(this.path)}>
            <ListItemIcon>
              <MetricTypeIcon/>
            </ListItemIcon>
            <ListItemText primary="Metric Types" />
          </ListItem>
        </Link>

        <Link to='/custodians'>
          <ListItem button  selected={RegExp('^/custodians(/.*)?$').test(this.path)}>
            <ListItemIcon>
              <CustodianIcon/>
            </ListItemIcon>
            <ListItemText primary="Custodians" />
          </ListItem>
        </Link>

        <ConditionalRender requires={Authorization.admin} component={() =>
          <Link to='/users'>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
        } />
        <ConditionalRender requires={Authorization.admin} component={() =>
          <Link to='/apidocs'>
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon />
              </ListItemIcon>
              <ListItemText primary="Api Docs" />
            </ListItem>
          </Link>
        } />
        <ConditionalRender requires={Authorization.admin} component={() =>
          <Link to='/audits'>
            <ListItem button>
              <ListItemIcon>
                <AuditIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        } />
        <ConditionalRender requires={Authorization.admin} component={() =>
          <Link to='/notifications'>
            <ListItem button selected={RegExp('^/notifications(/(new|(\\d+/edit)))?$').test(this.path)}>
              <ListItemIcon>
                <NotificationIcon/>
              </ListItemIcon>
              <ListItemText primary="All notifications"/>
            </ListItem>
          </Link>
        } />

        <div className={this.props.classes.version}>
          API {this.props.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
}

const styles = theme => ({
  title: {
    flex: 1
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  },
  drawerRoot: {
    overflowY: 'auto',
    '& a > div': {
      paddingLeft: 35
    }
  },
  purpleAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
    width: 50,
    height: 50
  },
  menuHead: {
    width: 300,
    flex: '0 0 auto',
    padding: 20,
    background: theme.palette.primary.light
  },
  avatarRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  userRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > button': {
      flex: 1,
      color: 'white',
      '& > span': {
        justifyContent: 'space-between',
      }
    }
  },
  notificationBadge: {
    fontWeight: 'bold'
  },
  issuerSelect: {
    color: 'white',
    marginLeft: 20,
    '&::before': {
      borderBottom: '1px solid white !important '
    },
  },
  issuerSelectIcon: {
    color: 'white',
    marginTop: -3,
  }
})
export default compose(
  connect(({ notifications: {unread}, version: { tag }, tokens: { currentUser }}) => ({ unread, tag, currentUser })),
  withStyles(styles)
)(Nav)
