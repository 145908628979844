import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'

export class Routes extends Component{

  render = () =>
    <Switch>
      <Route exact path="/firm_assets"                  component={Containers.FirmAssets.List}/>
      <Route exact path="/fund_summaries"                  component={Containers.FundSummary.List}/>
      <Route exact path="/file_imports"                    component={Containers.FileImport.List}/>
      <Route exact path="/file_imports/:id"                component={Containers.FileImport.Show}/>
      <Route exact path="/indices"                         component={Containers.Index.List}/>
      <Route exact path="/indices/new"                     component={Containers.Index.Form} requires={Authorization.admin}/>
      <Route exact path="/indices/:id"                     component={Containers.Index.Show}/>
      <Route exact path="/indices/:id/edit"                component={Containers.Index.Form} requires={Authorization.admin}/>
      <Route exact path="/fund_benchmarks"                 component={Containers.FundBenchmark.List}/>
      <Route exact path="/fund_benchmarks/new"             component={Containers.FundBenchmark.Form} requires={Authorization.admin}/>
      <Route exact path="/fund_benchmarks/:id"             component={Containers.FundBenchmark.Show}/>
      <Route exact path="/fund_benchmarks/:id/edit"        component={Containers.FundBenchmark.Form} requires={Authorization.admin}/>
      <Route exact path="/fund_benchmarks/:id/composition" component={Containers.FundBenchmark.Composition}/>
      <Route exact path="/metric_types"                    component={Containers.MetricType.List}/>
      <Route exact path="/metric_types/new"                component={Containers.MetricType.Form} requires={Authorization.admin}/>
      <Route exact path="/metric_types/:id"                component={Containers.MetricType.Show}/>
      <Route exact path="/metric_types/:id/edit"           component={Containers.MetricType.Form} requires={Authorization.admin}/>
      <Route exact path="/custodians"                      component={Containers.Custodian.List}/>
      <Route exact path="/custodians/new"                  component={Containers.Custodian.Form} requires={Authorization.admin}/>
      <Route exact path="/custodians/:id"                  component={Containers.Custodian.Show}/>
      <Route exact path="/custodians/:id/edit"             component={Containers.Custodian.Form} requires={Authorization.admin}/>
      <Route exact path="/funds"                           component={Containers.Fund.List}/>
      <Route exact path="/funds/new"                       component={Containers.Fund.Form} requires={Authorization.admin}/>
      <Route exact path="/funds/:id"                       component={Containers.Fund.Show}/>
      <Route exact path="/funds/:id/edit"                  component={Containers.Fund.Form} requires={Authorization.admin}/>
      <Route exact path="/fund_categories"                 component={Containers.FundCategory.List}/>
      <Route exact path="/fund_categories/new"             component={Containers.FundCategory.Form}  requires={Authorization.admin}/>
      <Route exact path="/fund_categories/:id"             component={Containers.FundCategory.Show}/>
      <Route exact path="/fund_categories/:id/edit"        component={Containers.FundCategory.Form}  requires={Authorization.admin}/>
      <Route exact path="/schemes"                         component={Containers.Scheme.List}/>
      <Route exact path="/schemes/new"                     component={Containers.Scheme.Form}  requires={Authorization.admin}/>
      <Route exact path="/schemes/:id"                     component={Containers.Scheme.Show}/>
      <Route exact path="/schemes/:id/edit"                component={Containers.Scheme.Form}  requires={Authorization.admin}/>
      <Route exact path="/profile"                         component={Containers.User.Profile}/>
      <Route exact path="/exchange_rates"                  component={Containers.ExchangeRate.List}/>
      <Route exact path="/reference_rates"                 component={Containers.ReferenceRate.List}/>
      <Route exact path="/users"                           component={Containers.User.List} requires={Authorization.admin}/>
      <Route exact path="/users/new"                       component={Containers.User.Form} requires={Authorization.admin}/>
      <Route exact path="/users/:id"                       component={Containers.User.Show}/>
      <Route exact path="/users/:id/edit"                  component={Containers.User.Form}/>
      <Route exact path="/audits"                          component={Containers.Audit.List} requires={Authorization.admin}/>
      <Route exact path="/notifications"                   component={Containers.Notification.List}/>
      <Route exact path="/notifications/new"               component={Containers.Notification.Form} requires={Authorization.admin}/>
      <Route exact path="/notifications/mine"              component={Containers.Notification.Mine}/>
      <Route exact path="/tokens/sign_in"                  component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"                   component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"                    component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"        component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"                  component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation"      component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/"                                component={() =>  <Redirect to={"/fund_summaries"}/>}/>
      <Route exact path="/not_found"                       component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                       component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                        component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"             component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"                    component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                         component={Containers.Docs.ApiDocs} />
      <Route exact path="/microsoft/callback"              component={Containers.Auth.MicrosoftCallbackSink} />
      <Route component={Containers.Status.NotFound} />
    </Switch>

}

export default withRouter(Routes)
