import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { FundCategoryActions, MetricTypeActions, DerivedMetricTypeActions, FundSummaryActions, FundActions } from 'actionsets'
import { connectQueryString } from 'containers/shared'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Authorization, compose, formatValue } from 'utils'
import withStyles from 'styles'
import { LabeledSelect, LabeledCheckbox, ConfirmationDialog, FormContext, DatePicker, LabeledSelectMulti } from 'components';
import { UserFavouriteFilterSelect } from 'containers/user_favourite_filters';
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { submitForm } from 'services/APIResource'
import moment from 'moment'

export class List extends Component {

  constructor(props) {
    super(props)
    FundSummaryActions.bindActions(this)
    FundActions.bindActions(this, 'fund')
    MetricTypeActions.bindActions(this, 'metricType')
    DerivedMetricTypeActions.bindActions(this, 'derivedMetricType')
    FundCategoryActions.bindActions(this, 'fundCategory')
  }

  state = { fundMetricFilters: { granularity: 'monthly' }, fundWithMetricDialogOpen: false }

  get filter() {
    const eom = new Date()
    eom.setDate(0)
    const defaults = { year: eom.getFullYear(), month: (eom.getMonth() + 1) }
    if (!this.props.filter.year || !this.props.filter.month) { this.handleFilterChange(defaults) }
    return { ...defaults, ...this.props.filter }
  }

  loadFundSummaries = () => {
    return this.actions.index({
      filter: this.filter,
      page: 1, pageSize: 2000,
    })
  }

  handleIntraMonthExport = () => {
    this.handleExportFile(`/api/fund_summaries/intra_month.xlsx`)
  }

  handleFundMetricsExport = () => {
    this.handleExportFile(`/api/fund_summaries/fund_metrics.xlsx`, (this.state.fundMetricFilters || {}))
    this.setState({ fundWithMetricDialogOpen: false })
  }

  handleWebsiteDataExport = () => {
    this.handleExportFile(`/api/fund_summaries/website_data.xlsx`)
  }

  handleFundSummaryExport = () => {
    this.handleExportFile(`/api/fund_summaries.xlsx`)
  }

  handleExportFile = (path, filter) => {
    const data = {
      Authorization: global.localStorage.auth,
      filter: (filter || this.filter),
    }
    submitForm((path), data, 'post')
  }

  dependsOn() {
    return Promise.all([
      this.loadFundSummaries(),
      this.actions.metricType.index({
        fields: { metricTypes: 'id,annualise,category,displayName' },
        page: 1, pageSize: 2000,
      }),
      this.actions.derivedMetricType.index({
        fields: { derivedMetricTypes: 'id,category,name,displayName' },
        page: 1, pageSize: 2000,
      }),
      this.actions.fundCategory.index({
        fields: { fundCategories: 'id,name' },
        page: 1, pageSize: 2000,
        order: 'name',
      }),
      this.actions.fund.index({
        fields: { fundCategories: 'id,shortName' },
        page: 1, pageSize: 2000,
        order: 'telCode',
      })
    ])
  }

  dependenciesMet() {
    return !!this.props.fundSummaries
  }

  handleFilterChange = (changes, callback) => {
    this.props.onFilterChange({ ...this.props.filter, ...changes }, callback)
  }

  handleValueChange = (name) => ({ target: { value } }) => {
    this.handleFilterChange({ [name]: value }, this.loadFundSummaries)
  }

  handleCheckChange = (name) => ({ target: { checked } }) => {
    this.handleFilterChange({ [name]: checked }, this.loadFundSummaries)
  }

  columns = [
    { name: "code", label: "Code", format: "string" },
    { name: "name", label: "Name", format: "string" },
    { name: "m1", label: "1 Month", format: "%" },
    { name: "m3", label: "3 Months", format: "%" },
    { name: "m6", label: "6 Months", format: "%" },
    { name: "y1", label: "1 Year", format: "%" },
    { name: "y2", label: "2 Years", format: "%" },
    { name: "y3", label: "3 Years", format: "%" },
    { name: "y5", label: "5 Years", format: "%" },
    { name: "y7", label: "7 Years", format: "%" },
    { name: "y10", label: "10 Years", format: "%" },
    { name: "sinceInception1", label: "Inception 1", format: "%" },
    { name: "sinceInception2", label: "Inception 2", format: "%" },
    { name: "sinceInception3", label: "Inception 3", format: "%" },
    { name: "sinceInception4", label: "Inception 4", format: "%" },
    { name: "sinceInception5", label: "Inception 5", format: "%" },
  ]

  get summaries() {
    return this.props.fundSummaries
  }

  get years() {
    return Array.from({ length: (new Date().getFullYear() - 1999) }, (_, i) => 2000 + i)
  }

  get months() {
    return moment.months().reduce((agg, val, i) => ({ ...agg, [i + 1]: val }), {})
  }

  get exportableMetricTypes() {
    const supportedDerivedMetrics = ['annualised_std_dev', 'risk_rating', 'monthly_alpha', 'alpha_3_year', 'tracking_error_3_year'
      , 'info_ratio_3_year', 'alpha_since_inception', 'tracking_error_since_inception', 'info_ratio_since_inception']
    return [
      ...this.props.metricTypes.map(m => [m.id, m.displayName]),
      ...this.props.derivedMetricTypes.filter(d => supportedDerivedMetrics.includes(d.name)).map(m => [`d${m.id}`, m.displayName])
    ]
  }

  render = () => {
    return <div className={this.props.classes.wrapper}>
      <PageContainer>
        <ActionHeader title="Fund Summary" style={{ marginRight: 16 }}>
          <ConfirmationDialog title="Export Funds With Metrics" confirmLabel="Export"
            open={this.state.fundWithMetricDialogOpen}
            onConfirm={this.handleFundMetricsExport}
            onCancel={() => { this.setState({ fundWithMetricDialogOpen: false }) }} maxWidth={'lg'}>
            <FormContext context={this.state.fundMetricFilters} onChange={(state) => this.setState({ fundMetricFilters: state })}>
              <LabeledSelectMulti fullWidth label="Metric Types" member="metricTypeIds" options={this.exportableMetricTypes} selectAll />
              <LabeledSelectMulti fullWidth label="Funds" member="fundIds" options={this.props.funds} keyField="id" renderText={(o) => `${o.telCode} - ${o.shortName}`} selectAll />
              <LabeledSelectMulti fullWidth label="Fund Categories" member="fundCategoryIds" options={this.props.fundCategories} keyField="id" textField="name" />
              <DatePicker clearable fullWidth member="fromDate" />
              <DatePicker clearable fullWidth member="toDate" />
              <LabeledSelect fullWidth label="Granularity" member="granularity" options={{ monthly: "Monthly", daily: "Daily" }} />
            </FormContext>
          </ConfirmationDialog>
          <Button color="secondary" style={{ color: 'white', marginRight: 5 }} variant="contained" onClick={() => { this.setState({ fundWithMetricDialogOpen: true }) }}>Metrics&emsp;<DownloadIcon /></Button>
          {["HAML", "ALL"].includes(Authorization.selectedIssuerName) &&
            <>
              <Button color="secondary" style={{ color: 'white', marginRight: 5 }} variant="contained" onClick={this.handleWebsiteDataExport}>Website Data&emsp;<DownloadIcon /></Button>
              <Button color="secondary" style={{ color: 'white', marginRight: 5 }} variant="contained" onClick={this.handleIntraMonthExport}>Intra-Month&emsp;<DownloadIcon /></Button>
            </>
          }
          <Button color="secondary" style={{ color: 'white' }} variant="contained" onClick={this.handleFundSummaryExport}>Export&emsp;<DownloadIcon /></Button>
        </ActionHeader>
        <UserFavouriteFilterSelect filter={this.filter} onFilterChange={(filter) => this.handleFilterChange(filter, this.loadFundSummaries)}
          appliesTo={["metricTypeIds", "fundCategoryIds", "showSubFunds", "showAllBenchmarks"]} path={this.props.match.path} />
        <LabeledSelect label="Year" options={this.years} value={this.props.filter.year} onChange={this.handleValueChange('year')} style={{ marginRight: 16 }} />
        <LabeledSelect label="Month" options={this.months} value={this.props.filter.month} onChange={this.handleValueChange('month')} style={{ marginRight: 16 }} />
        <LabeledSelectMulti
          style={{ width: 280, marginRight: 16 }}
          label="Fund Categories"
          value={this.props.filter.fundCategoryIds}
          onChange={this.handleValueChange("fundCategoryIds")}
          options={this.props.fundCategories}
          keyField="id"
          textField="name" />
        <LabeledSelectMulti
          style={{ width: 300, marginRight: 16 }}
          onChange={this.handleValueChange("fundIds")}
          value={this.props.filter.fundIds}
          label="Funds"
          options={this.props.funds}
          keyField="id"
          renderText={(o) => `${o.telCode} - ${o.shortName}`}
          selectAll />
        <LabeledSelectMulti
          style={{ width: 300, marginRight: 16 }}
          label="Metric Types"
          options={this.props.metricTypes.filter(mt => (mt.annualise && mt.category === "fund_returns"))}
          value={this.props.filter.metricTypeIds}
          onChange={this.handleValueChange("metricTypeIds")}
          keyField="id"
          textField="displayName"
          selectAll />
        <LabeledCheckbox label="Show Subfunds" value={this.props.filter.showSubFunds} onChange={this.handleCheckChange("showSubFunds")} />
        <LabeledCheckbox label="Show All Benchmarks" value={this.props.filter.showAllBenchmarks} onChange={this.handleCheckChange("showAllBenchmarks")} />
        <div className={this.props.classes.containerDiv}>
          <Table className={this.props.classes.table}>
            <TableBody>
              <TableRow className="header top-labels">
                {this.columns.map(col => <TableCell key={col.name}><b>{col.label}</b></TableCell>)}
              </TableRow>
              {this.summaries.map((summary, idx) => {
                const showFundRow = (!this.summaries[idx - 1] || this.summaries[idx - 1].fundId !== summary.fundId)
                const showSchemeRow = (!this.summaries[idx - 1] || this.summaries[idx - 1].schemeName !== summary.schemeName || this.summaries[idx - 1].issuerName !== summary.issuerName)
                const link = showFundRow ? `/funds/${summary.fundId}` : summary.benchmarkId ? `/fund_benchmarks/${summary.benchmarkId}/composition` : undefined
                return <Fragment key={summary.id}>
                  {showSchemeRow && <TableRow className="scheme-row">
                    {this.columns.map(col => (
                      <TableCell key={col.name}>
                        {col.name === 'code' && summary.issuerName}
                        {col.name === 'name' && summary.schemeName}
                      </TableCell>
                    ))}
                  </TableRow>}
                  {showFundRow && <TableRow className="spacer-row">
                    {this.columns.map(col => <TableCell key={col.name}>{col.name.startsWith('sinceInception') && formatValue('date', summary[col.name.replace('sinceInception', 'inception')])}</TableCell>)}
                  </TableRow>}
                  <TableRow>
                    {this.columns.map(col =>
                      <TableCell key={col.name}
                        onClick={link && (col.name === 'code' || col.name === 'name') ? () => this.props.history.push(link) : undefined}
                        className={summary[col.name] < 0 ? 'negative' : 'positive'}>
                        {(showFundRow && col.name === 'name') ? <b>{summary[col.name]}</b> : formatValue(col.format, summary[col.name], 2)}
                      </TableCell>)}
                  </TableRow>
                </Fragment>
              })}
              {this.summaries.length === 0 && <TableRow>
                <TableCell colSpan={this.columns.length}>{"No data found for the selected criteria"}</TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </div>
      </PageContainer>
    </div>
  }
}

const styles = theme => ({
  table: {
    textAlign: "left",
    position: "relative",
    borderCollapse: "collapse",
  },
  containerDiv: {
    overflowX: 'scroll',
    overfloyY: 'scroll',
    height: 'calc(100vh - 280px)',
    marginTop: 4,
    '& .top-labels': {
      height: 30,
    },
    '& .header': {
      '& td': {
        background: theme.palette.secondary.light,
      },
      transition: 'top 10ms ease 0s'
    },
    '& table': {
      border: 'solid 1px lightgray',
    },
    '& tr': {
      height: '30px',
      padding: 0,
      margin: 0,
      background: 'white',
      border: 'none',
      '&:hover': {
        background: theme.palette.primary.light,
        '& td:hover': {
          background: theme.palette.primary.main,
          color: 'white',
          cursor: 'pointer'
        }
      }
    },
    '& tr.spacer-row': {
      background: '#D3DCE0',
      height: '20px',
    },
    '& tr.spacer-row td': {
      paddingTop: 4,
      paddingBottom: 0,
      color: 'gray',
    },
    '& tr.scheme-row': {
      background: '#d3a908',
      height: '30px',
    },
    '& tr.scheme-row td': {
      paddingTop: 4,
      paddingBottom: 0,
      fontWeight: 600
    },
    '& tr.header td': {
      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
      position: 'sticky',
      top: -1
    },
    '& td': {
      whiteSpace: 'nowrap',
      paddingLeft: 10,
      paddingRight: 10,
      border: 'none',
    },
    '& td.negative': {
      color: 'red',
    }
  },
  wrapper: {
    maxWidth: '100vw',
    overflowX: 'hidden',
    '& > div': {
      width: '85%',
      maxWidth: 4000,
    }
  },
})

export default compose(
  Dependent({ loader: true, clearOnLoad: true }),
  withStyles(styles),
  connectQueryString('fundSummaries'),
  connect(({ metricTypes }) => metricTypes),
  connect(({ derivedMetricTypes }) => derivedMetricTypes),
  connect(({ fundSummaries }) => fundSummaries),
  connect(({ fundCategories }) => fundCategories),
  connect(({ funds }) => funds),
)(List)
