import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MetricTypeActions } from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledSelect } from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'

export class Form extends InstanceFormMixin(Component) {

  constructor(props) {
    super(props)
    MetricTypeActions.bindActions(this)
  }

  get formObject() {
    return { ...this.props.metricType, ...this.state.formAttributes }
  }

  dependsOn() {
    if (this.editMode) {
      return this.actions.show(this.objectId)
    } else {
      return this.actions.set({ category: "fund_returns", annualise: false, isDefault: false, sequence: 100000 })
    }
  }

  handleDisplayNameChange = ({ target: { value } }) => {
    let name = this.formObject.name;
    if ((this.formObject.name || '') === this.convertToSystemName(this.formObject.displayName)) {
      name = this.convertToSystemName(value)
    }
    this.handleFormObjectChange({ ...this.formObject, displayName: value, name })
  }

  handleNameChange = ({ target: { value } }) => {
    if (value === this.convertToSystemName(value)) {
      this.handleFormObjectChange({ ...this.formObject, name: value })
    }
  }

  convertToSystemName = (displayName) => {
    return (displayName || '').toLowerCase().replace(/ /g, "_").replace(/%/g, 'percent').replace(/\W/g, '')
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Edit Metric Type - ${this.formObject.displayName || ''}`} />
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='displayName' onChange={this.editMode ? undefined : this.handleDisplayNameChange} />
          <TextField fullWidth disabled={this.editMode} member='name' label="System Name" onChange={this.handleNameChange} />
          <LabeledSelect fullWidth label="Format" options={{ "%": "Percentage", "#": "Number" }} member="format" />
          <LabeledSelect fullWidth label="Decimal Places" options={['0', '1', '2', '3', '4', '5', '6', '7', '8']} member="decimalPlaces" />
          <TextField fullWidth member='fundSummarySequence' type="number" />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </PageContainer>
}

const styles = {
}

export default compose(
  Dependent({ loader: true }),
  withStyles(styles),
  connect(({ metricTypes }) => metricTypes),
)(Form)
