import { ProtectedJsonAPI } from '.'
import { encodeJSONApiParams } from 'utils'

export const Tokens = ProtectedJsonAPI.extend(
  '/tokens',
  {
    create:             endpoint => endpoint.method('post'),
    refresh:            endpoint => endpoint.method('put').paramsHandler((params) => encodeJSONApiParams({ selectedIssuerName: params?.data?.attributes?.selectedIssuerName }) ).headers((headers, item) => { return {...headers, ...(item.refreshToken ? {Authorization: item.refreshToken, "X-Authorization": item.refreshToken} : {}) }}),
    destroy:            endpoint => endpoint.method('delete'),
    forgot:             endpoint => endpoint.method('post').path('forgot'),
    reset:              endpoint => endpoint.method('post').path('reset'),
    resendConfirmation: endpoint => endpoint.method('post').path('resend_confirmation'),
    confirm:            endpoint => endpoint.method('post').path('confirm'),
    acceptInvite:       endpoint => endpoint.method('post').path('accept_invite')
  }
)
