import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { SchemeActions, IssuerActions}    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, Select }      from 'components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    SchemeActions.bindActions(this)
    IssuerActions.bindActions(this, 'issuer')
    if (!this.editMode) {
      this.state = { formAttributes: { excludeFromFirmAssetsTotal: false, hasFirmAssetsDetailView: true, hasFlowInformation: true } }
    }
  }

  get formObject(){
    return {...this.props.scheme, ...this.state.formAttributes}
  }

  dependsOn() {
    const promises = [
      this.actions.issuer.index({
        fields: { issuers: 'id,name' },
        page: 1, pageSize: 2000,
      }),
    ]
    if (this.editMode) {
      promises.push(
        this.actions.show(this.objectId)
      )
    } else {
      return this.actions.set()
    }

    return Promise.all(promises);
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={this.editMode ? `Edit Scheme - ${this.formObject.name || ''}` : 'New Scheme'}/>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <Select fullWidth label="Issuer" member='issuerId'>
            {(this.props.issuers || []).map(issuer =>
              <MenuItem key={issuer.id} value={issuer.id}>{issuer.name}</MenuItem>
            )}
          </Select>
          <TextField fullWidth member='sequence' type='number' />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </PageContainer>
}

const styles = {
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({ schemes }) => schemes),
  connect(({ issuers }) => issuers),
)(Form)
