import React, { PureComponent, Fragment, Children } from 'react'
import PropTypes from 'prop-types'
import { humanize } from 'utils'
/**
 * Goals for this component:
 *
 * * The ability to have multiple form contexts in a single render.
 *   Overcomes the disadvantages of the convention in the FormMixin to
 *   have a single context per component
 *
 * * Copy/Pasteability  - Works with any HTML5-like input component without
 *   the need for customised wrapping components
 *
 * * Supports complex nested properties as the FormMixin currently does
 *
 * * Supports an error context with an identical structure to the context
 *
 * * Plays nicely with enhanced input components (such as those in the material-ui library)
 *
 * * Looks like 'plain-old-react'. We want to be able to remove the FormContext and
 * still have a functioning and visually equivalent layout
 *
 * * Multiple contexts can be nested and reference different parts of the context tree
 *
 * * Zero coupling to our data model, redux, and API. All we need is
 *   React a context object, and a change handler
 *
 * Performance (Need to check on mobile devices)
 *
 * We retain and rename FormMixin => InstanceFormMixin to make it clear this is a very
 * tight and opinionated coupling
 */
export class FormContext extends PureComponent{

  static propTypes = {
    context:  PropTypes.object,
    onChange: PropTypes.func
  }

  get formContext(){
    return this.path.reduce((memo, part) => memo ? memo[part] : null, this.props.context || {})
  }

  get errorContext(){
    return this.path.reduce((memo, part) => memo ? memo[part] : null, this.props.errorContext || {}) || {}
  }

  get path(){
    return this.props.member ? this.props.member.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== "") : []
  }

  onChange = member => ({target: { type, value, checked }}) =>{
    switch(type){
    case 'checkbox': return this.setAttribute(member, checked)
    case 'number':   return this.setAttribute(member, parseFloat(value))
    default:         return this.setAttribute(member, value)
    }
  }

  value = member =>
    this.attribute(member)

  checked = member =>
    this.attribute(member) === true

  setAttribute(attribute, value) {
    const attributeChain = this.path.concat(attribute.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== ""))
    const updatedContext = {...this.props.context}
    const valueObject = attributeChain.slice(0, -1).reduce((result, att) => (result[att] = Array.isArray(result[att]) ? [...result[att]] : {...result[att]}), updatedContext)
    valueObject[attributeChain[attributeChain.length-1]] = value
    this.props.onChange && this.props.onChange(updatedContext, this.props.context)
  }

  submit = event =>{
    event.preventDefault()
    this.props.onSubmit && this.props.onSubmit(this.props.context)
  }

  deepGet(name, object) {
    return name.split(/(?:\[(?=\d)|(?!=\d)\]\.?|\.)/).filter(x => x !== "").reduce((memo, part) => memo ? memo[part] : null, object)
  }

  attribute = name => {
    return this.deepGet(name, this.formContext)
  }

  fetchError = member => {
    return this.deepGet(member, this.errorContext)
  }

   // eslint-disable-next-line
  propsForMember = ({type, member, errorMember, onChange, value, checked, error, helperText, label, placeholder, context, multiple, propTypes={}}={}) => {
    const props = {}
    if(member !== void 0){
      /**
       * We've opted in to the form context.
       * Let's create some default props
       */
      if (type === 'checkbox'){
        props.checked = this.value(member) || false
      }
      else{
        props.value    = this.value(member)
        if(props.value === undefined || props.value === null){
          props.value = multiple ? [] : ''
        }
      }
      props.onChange    = this.onChange(member)
      const errorText   = this.fetchError(errorMember || member)
      if(errorText){
        props.error       = true
        props.helperText  = errorText
      }
      props.name        = member
      props.label       = humanize(member.replace(/^.*(?:\[(?=\d)|(?!=\d)\]|\.)(.*)$/, "$1"))
      props.placeholder = ""
    }

    /**
     * Provided props are always preferred over defaults
     */
    if(onChange)               props.onChange    = onChange
    if(value       !== void 0) props.value       = value
    if(checked     !== void 0) props.checked     = checked
    if(error       !== void 0) props.error       = error
    if(helperText  !== void 0) props.helperText  = helperText
    if(label       !== void 0) props.label       = label
    if(placeholder !== void 0) props.placeholder = placeholder
    if(context)                props.context = context
    return props
  }


  renderChild = (elm) => {
    if(!(elm && elm.type)) {
      /**
       * We are not a valid input element
       */
      return elm
    }

    let {
      type: Type,
      props: {
        member, errorMember, onChange, value, placeholder, checked, children,
        error, context, errorContext, helperText, label, inputRef, ...props
      }
    } = elm

    if(Type === this.constructor){
      /**
       * We are a nested form context
       */
      if(member){
        onChange      = onChange      || this.props.onChange
        context       = context       || this.props.context
        errorContext  = errorContext  || this.props.errorContext
      }
      return <Type onChange={onChange} context={context} errorContext={errorContext} member={member} {...props}>{children}</Type>
    }
    /**
     * We are a wrapped child
     */
    // eslint-disable-next-line
    const memberProps = this.propsForMember({...elm.props, propTypes: Type.propTypes})
    children = Children.map(children, child => typeof child !== 'string' ? this.renderChild(child) : child)

    return (
      <Type ref={inputRef} {...{...memberProps, ...props}}>
        {(children && children.length === 1) ? children[0] : children}
      </Type>
    )
  }

  render = () =>
    this.props.onSubmit ? this.renderAsForm() : this.renderAsFragment()

  renderAsForm = () =>
    <form onSubmit={this.submit} ref={ref => this.formRef = ref}>
      {this.props.autoComplete === false && <input type='hidden' autoComplete="false" />}
      {Children.map(this.props.children, this.renderChild)}
    </form>

  renderAsFragment = () =>
    <Fragment>
      {Children.map(this.props.children, this.renderChild)}
    </Fragment>

}

export default FormContext
